import React from 'react'
import theme from './config/theme'
import ThemeProvider from '@material-ui/styles/ThemeProvider'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>
  )
}
