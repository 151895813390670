import "@fontsource/dm-sans"
import { createMuiTheme } from '@material-ui/core/styles'
import { amber, common, green, grey, red, white } from '@material-ui/core/colors'

const rawTheme = createMuiTheme({
  palette: {
    background: {
      default: common.white,
      placeholder: grey[200],
    },
    primary: {
      main: common.black,
    },
    secondary: {
      main: grey[700],
    },
    warning: {
      main: amber[500],
    },
    error: {
      main: red[300],
    },
    success: {
      main: green[500],
    },
  },
  typography: {
    fontFamilyHeader: `'DM Sans', sans-serif;`,
    fontFamily: `'DM Sans', -apple-system, system-ui, BlinkMacSystemFont, sans-serif`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
  },
});

const fontHeader = {
  color: common.black,
  fontFamily: rawTheme.typography.fontFamilyHeader,
  fontWeight: 'normal',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: '4.236rem',
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      color: common.black,
      fontSize: '2.618rem',
      letterSpacing: -1.5,
      lineHeight: 1.15,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      color: grey[700],
      fontSize: '1.618rem',
      letterSpacing: -.15,
      lineHeight: 1.15,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1rem',
        textTransform: 'none'
      },
    },
  },
};

export default theme;
